<template>
  <div>
      <section class="section services-insurance">
        <h2 class="vb-heading mb-4">Assicurazione medico bagaglio</h2>
        <b-row>
          <b-col cols="12">
            <p>
              I pacchetti viaggio includono sempre la <b>Polizza Base o Top Booking Basic</b> (per prodotto Eden Viaggi). I servizi inclusi sono:
            </p>
            <ul>
              <li>
                rimborso o pagamento spese mediche con massimali di 1.000, 5.000 o 30.000 € in base al Paese di destinazione
              </li>
              <li>
                l'assicurazione bagaglio e acquisti di prima necessità in caso di furto, mancata consegna bagagli, scippo, rapina, ecc.
              </li>
              <li>
                assistenza alla persona 22 prestazioni fornite attraverso la struttura organizzativa in funzione 24h. Tra le prestazioni: consulenza medica; rimpatrio e/o rientro sanitario; rientro anticipato o prolungamento del soggiorno; protezione carte di credito; anticipo cauzione penale all’estero.
              </li>
              <li>
                il servizio My Clinic: E’ la piattaforma digitale attraverso cui potrai ricevere assistenza in viaggio per tutta la tua famiglia scaricando l'App MyAlpitour World.
              </li>
              <li>
                il servizio Senior Assistance: un servizio di assistenza medica per i familiari over 65 che sono restati a casa
              </li>
            </ul>
            <p>
              <b>I pacchetti Edenviaggi non includono il servizio My Clinic e il servizio Senior Assistance.</b>
            </p>
            <p>
              Su alcune destinazioni come USA e Canada è fortemente consigliata la sottoscrizione di assicurazioni mediche con massimali più ampi. Parlane col tuo assicuratore.
            </p>
            <p>
              Per ulteriori approfondimenti consulta le <router-link :to="{ name: 'terms' }">condizioni generali</router-link> del sito Vacanze by Vadobay e le 
              <a href="https://vacanze.vadobay.com/assets/condizioni_uso_vacanzeByVadoBay.pdf">condizioni assicurative di Alpitour</a>.
            </p>
            <p>
              (Ricorda che nei pacchetti viaggio non è mai inclusa l’assicurazione annullamento. Per maggiori informazioni scrivici a <a :href="'mailto:'+get_config().site.emailSupport" v-html="get_config().site.emailSupport"/>)
            </p>
          </b-col>
        </b-row>
      </section>
  </div>
</template>

<script>
//import Card from '@library/blocks/card'

export default {
  name: 'services-insurance',

  props: {
  },

  components: {
    //'vb-card': Card,
  },

  data() {
    return {
    }
  },

  created() {
  },

  mounted() {
  },

  methods: {
  }
}
</script>

<style lang="scss" scoped>

  .services-insurance {
    background-color: $white;
    border-radius: $border-radius-lg;
    padding: $grid-gutter-width;
  }

</style>