<template>
  <div class="vb-pratica">

    <services
        :services="services"
    />

    <h1 class="vb-heading h2">I dati dei viaggiatori</h1>
    <p class="mb-0">Verifica con attenzione i dati personali dei partecipanti al viaggio che devono corrispondere a quelli presenti
    sui documenti che utilizzerai per i checkin aeroportuali e alberghieri.</p>
    <b-row>
      <card-pax v-for="(paxs, paxs_index) in passengers"
                :key="_uid+paxs_index"
                :pax="paxs"
                :col="'col-xl-6'"
      />
    </b-row>

  </div>
</template>

<script>
import Services from '@alpitour/components/blocks/services.vue'
import CardPax from '@alpitour/components/blocks/card-pax.vue'
export default {
  name: 'pratica',

  props: {
    services: Object,
    passengers: Array
  },

  components: {
    'services': Services,
    'card-pax': CardPax,
  },

  data() {
    return {

    }
  },

  created() {
  },

  mounted() {
  },

  methods: {
  }
}
</script>
