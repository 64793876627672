<template>
  <div class="vb-services">

    <flights
        :flights="services.flights"
    ></flights>

    <hotels
        class="mt-4"
        :hotels="services.hotels"
        :supplements="services.travelSupplements"
    ></hotels>

  </div>
</template>

<script>
import Flights from '@alpitour/components/blocks/flights.vue'
import Hotels from '@alpitour/components/blocks/hotels.vue'
export default {
  name: 'services',

  props: {
    services: Object
  },

  components: {
    'flights': Flights,
    'hotels': Hotels,
  },

  data() {
    return {

    }
  },

  created() {
  },

  mounted() {
  },

  methods: {
  }
}
</script>

<style lang="scss">

.vb-services {
  background-color: #fff;
  border-radius: $border-radius-lg;
  padding: 1.25rem;

  &:not(.vb-quote &) {
    margin-bottom: $grid-gutter-width;

    @include media-breakpoint-up(md) {
      margin-bottom: $grid-gutter-width*2;
    }
  }
}

</style>
