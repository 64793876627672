<template>
  <div>
    <h2 class="vb-heading h5">I voli inclusi</h2>

      <div v-for="(flight, flight_index) in flights" :key="flight+flight_index">
        <div v-for="(segment, segment_index) in flight.segments" :key="segment+segment_index">
          <div v-for="(route, route_index) in segment.routes" :key="route+route_index">

            <div class="vb-flights">
              <div class="vb-flights__date">
                <vb-icon
                    :class="flight_index === flights.length - 1 ? 'vb-flights__date-icon vb-flights__date-icon--reverse' : 'vb-flights__date-icon'"
                    :name="'airplane'"
                    :size=25
                    :color="'ced4da'"
                />
                <span class="vb-flights__date-text" v-html="
                  parse_date(route.departureDateTime.split(' ')[0]).day_number + ' ' +
                  parse_date(route.departureDateTime.split(' ')[0]).month_string + ' ' +
                  parse_date(route.departureDateTime.split(' ')[0]).year"
                />
              </div>
              <div class="vb-flights__info">
                <span v-html="'Partenza da ' + '<span class=\'text-capitalize\'>' + route.departureAirportName.toLowerCase() + '</span>' + ' (' + route.departureAirportId + ')'" />
                <span v-html="' alle ' + route.departureDateTime.split(' ')[1].slice(0,5)" />,
                <span v-html="' arrivo a ' + '<span class=\'text-capitalize\'>' + route.arrivalAirportName.toLowerCase() + '</span>' + ' (' + route.arrivalAirportId + ')'" />
                <span v-html="' alle ' + route.arrivalDateTime.split(' ')[1].slice(0,5)" />
                <span v-if="route.arrivalDateTime.split(' ')[0] !==  route.departureDateTime.split(' ')[0]" v-html="' (+1)'" />
                <span v-html="' - Compagnia ' + route.operatingCarrier" />
                <span v-html="' - Classe ' + route.classType" />
              </div>
            </div>

          </div>
        </div>
      </div>
      
      <disclaimer-flights-info />
  </div>
</template>

<script>
import { parse_date} from '@utilities/commons'
import disclaimerFlightsInfo from './detail/disclaimer-flights-info.vue'
export default {
  name: 'flights',

  props: {
    flights: Array,
  },

  components: {
    'disclaimer-flights-info': disclaimerFlightsInfo,
  },

  data() {
    return {

    }
  },

  created() {
    this.parse_date = parse_date
  },

  mounted() {
  },

  methods: {
  }
}
</script>

<style lang="scss">

.vb-flights {
  margin-top: $grid-gutter-width/2;

  @include media-breakpoint-up(xl) {
    display: flex;
    align-items: center;
  }

  &__date {
    display: flex;
    align-items: center;
    margin-right: $grid-gutter-width/3;
    margin-bottom: $grid-gutter-width/4;

    @include media-breakpoint-up(xl) {
      margin-bottom: 0;
    }

    &-icon {
      margin-right: $grid-gutter-width/4;

      &--reverse {
        transform: scaleX(-1);
      }
    }

    &-text {
      font-weight: $vb-font-weight-semibold;
    }
  }
}

</style>
