<template>
  <div>
    <h2 class="vb-heading h5">Pernotto e servizi</h2>
    <div v-for="(hotel, hotel_index) in hotels" :key="hotel+hotel_index">

      <div class="vb-hotels">
        <div class="vb-hotels__wrap">
          <div class="vb-hotels__item">
            <vb-icon
                class="vb-hotels__item-icon"
                :name="'hotel'"
                :size=25
                :color="'ced4da'"
            />
            <span class="vb-hotels__item-text" v-html="hotel.name" />
          </div>
          <div class="vb-hotels__item ">
            <span v-html="'IN ' + reverse_date(hotel.checkIn)" />
            <vb-icon
                class="vb-hotels__item-icon vb-hotels__item-icon--check"
                :name="'arrow-r'"
                :size=25
                :color="'ced4da'"
            />
            <span v-html="'OUT ' + reverse_date(hotel.checkOut)" />
          </div>

          <div class="vb-hotels__wrap">
            <div class="vb-hotels__item">
              <vb-icon
                  class="vb-hotels__item-icon"
                  :name="'bed'"
                  :size=25
                  :color="'ced4da'"
              />
              <span v-html="room_parsed.label" />
            </div>
            <div class="vb-hotels__item">
              <vb-icon
                  class="vb-hotels__item-icon"
                  :name="'cutlery'"
                  :size=25
                  :color="'ced4da'"
              />
              <span v-html="room_parsed.treatment.label" />
            </div>
            <div class="vb-hotels__item">
              <vb-icon
                  class="vb-hotels__item-icon"
                  :name="'bus'"
                  :size=25
                  :color="'ced4da'"
              />
              <span class="text-uppercase" v-html="'Trasferimenti *'" />
            </div>
            <div class="vb-hotels__item" v-b-modal="'insurance'">
              <vb-icon
                  class="vb-hotels__item-icon"
                  :name="'shield'"
                  :size=25
                  :color="'ced4da'"
              />
              <span class="text-uppercase" v-html="'Assicurazione'" />
              <vb-icon
                  class="ml-1"
                  :name="'info'"
                  :color="'adb5bd'"
                  :size="14"
              />
            </div>
            <vb-modal
                :vb_options="{
                  id: 'insurance',
                  size: 'lg',
                  title: 'I pacchetti viaggio includono',
                  hide_footer: true,
                }"
            >
              <services-insurance class="p-0 mt-0" />
            </vb-modal>
          </div>

        </div>
        <div class="vb-hotels__service" v-if="!supplements.every(suppl => !suppl.visible)">
          <vb-toggle
              :vb_options="{
                  id: 'service_'+hotel_index,
                  btn_text: 'Servizi inclusi nel pacchetto',
                }"
          >
            <ul>
              <li v-for="(suppl, suppl_index) in supplements.filter(suppl => suppl.visible)" :key="suppl+suppl_index">
                {{suppl.name.toLowerCase()}}
              </li>
            </ul>
          </vb-toggle>

        </div>
      </div>


    </div>
  </div>
</template>

<script>
import {reverse_date} from '@utilities/commons'
import ServicesInsurance from '@alpitour/components/blocks/detail/assicurazione-medico-bagaglio.vue'
export default {
  name: 'hotels',

  props: {
    hotels: Array,
    supplements: Array
  },

  components: {
    'services-insurance': ServicesInsurance,
  },

  data() {
    return {
      room_parsed : this.parse_room()
    }
  },

  created() {
    this.reverse_date = reverse_date
  },

  mounted() {
  },

  methods: {

    parse_room() {
      let solutionRoom = {}

      this.hotels.forEach(hotel => {
        hotel.offers.forEach(offer => {
          offer.rooms.forEach(room => {
            room.boards.forEach(board => {
              if(board.selected) {
                solutionRoom = {
                  id: room.id,
                  label: room.name,
                  price: parseFloat(room.priceDescriptor.netPrice) +  parseFloat(board.totalPrice),
                  treatment: {id: board.id, label: board.description, totalPrice: board.totalPrice}
                }
              }
            })
          })
        })
      })

      return solutionRoom
    }

  }
}
</script>

<style lang="scss">

  .vb-hotels {
    padding-top: $grid-gutter-width/2;

    &__wrap {
      display: flex;
      align-items: center;
      flex-wrap: wrap;
    }

    &__item {
      display: flex;
      align-items: center;
      margin-right: $grid-gutter-width/2;
      margin-bottom: $grid-gutter-width/4;

      &-icon {
        margin-right: $grid-gutter-width/5;

        &--check {
          margin-left: $grid-gutter-width/5;
        }
      }
    }

    &__service {
      margin-top: $grid-gutter-width;
      width: 100%;

      @include media-breakpoint-up(lg) {
        width: 40%;
      }

      .vb-toggle {
        padding: 0;
      }

      li {
        list-style: circle;
      }
    }

  }

</style>
